import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const usePublicPageHeadingSection = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page Public" } }
        language: { code: { eq: RO } }
      ) {
        templatePageFields {
          templatePageHeadingSection {
            title
            subtitle
            headingSectionImage {
              localFile {
                url
              }
            }
            buttonLink {
              title
              url
              target
            }
          }
        }
      }
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page Public" } }
        language: { code: { eq: EN } }
      ) {
        templatePageFields {
          templatePageHeadingSection {
            title
            subtitle
            headingSectionImage {
              localFile {
                url
              }
            }
            buttonLink {
              title
              url
              target
            }
          }
        }
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return (
    pages[language]?.templatePageFields?.templatePageHeadingSection ||
    wpPageRo?.templatePageFields?.templatePageHeadingSection
  )
}
