import { useStaticQuery, graphql } from "gatsby"
import { useTranslation } from "react-i18next"

export const usePublicPageMiddleSection = () => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const { wpPageEn, wpPageRo } = useStaticQuery(graphql`
    query {
      wpPageEn: wpPage(
        template: { templateName: { eq: "Page Public" } }
        language: { code: { eq: EN } }
      ) {
        templatePageFields {
          templatePageMiddleSection {
            middleSectionServices {
              service {
                title
                link {
                  url
                  title
                  target
                }
                icon {
                  localFile {
                    url
                  }
                }
              }
            }
            middleSectionContent {
              item {
                text
                image {
                  sourceUrl
                }
              }
            }
          }
        }
      }
      wpPageRo: wpPage(
        template: { templateName: { eq: "Page Public" } }
        language: { code: { eq: RO } }
      ) {
        templatePageFields {
          templatePageMiddleSection {
            middleSectionServices {
              service {
                title
                link {
                  url
                  title
                  target
                }
                icon {
                  localFile {
                    url
                  }
                }
              }
            }
            middleSectionContent {
              item {
                text
                image {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `)

  const pages = {
    en: wpPageEn,
    ro: wpPageRo,
  }

  return (
    pages[language]?.templatePageFields?.templatePageMiddleSection ||
    wpPageRo?.templatePageFields?.templatePageMiddleSection
  )
}
