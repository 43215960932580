import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import { usePublicPageHeadingSection } from "../hooks/PublicPage/usePublicPageHeadingSection"
import { usePublicPageMiddleSection } from "../hooks/PublicPage/usePublicPageMiddleSection"
import { usePublicPageBottomSection } from "../hooks/PublicPage/usePublicPageBottomSection"
import Layout from "../components/Layout/Layout"
import TemplatePageHeaderSection from "../components/TemplatePageHeaderSection/TemplatePageHeaderSection"
import TemplatePageContentSection from "../components/TemplatePageContentSection/TemplatePageContentSection"
import TemplatePageConfigSection from "../components/TemplatePageConfigSection/TemplatePageConfigSection"
import Footer from "../components/Footer/Footer"
import SeoPage from "../components/seo-page"

const PublicPage = ({ location }) => {
  const { i18n } = useTranslation()
  const { language } = i18n
  const seoFields = {
    keyword: {
      en: "photovoltaic panels",
      ro: "panouri fotovoltaice",
    },
    slug: {
      en: "Panouri fotovoltaice pentru comunitate și spații publice.",
      ro: "Panouri fotovoltaice pentru comunitate și spații publice.",
    },
  }
  function getSeoFields(field) {
    return seoFields[field]?.[language] ?? seoFields[field]?.ro;// return ro fields if current language does not have them
  }
  const {
    title: headingTitle,
    subtitle,
    buttonLink: headingButton,
    headingSectionImage,
  } = usePublicPageHeadingSection()
  const {
    middleSectionServices,
    middleSectionContent: { ...content },
  } = usePublicPageMiddleSection()
  const {
    title: bottomTitle,
    programText,
    notes,
    backgroundImage,
    buttonLink: bottomButton,
    icon,
  } = usePublicPageBottomSection()
  return (
    <Layout>
      <SeoPage keyword={getSeoFields("keyword")} slug={getSeoFields("title")} />
      <TemplatePageHeaderSection
        title={headingTitle}
        subtitle={subtitle}
        buttonLink={headingButton}
        image={headingSectionImage}
        location={location}
      />
      <TemplatePageContentSection
        content={Object.values(content)}
        services={middleSectionServices}
      />
      <TemplatePageConfigSection
        title={bottomTitle}
        programText={programText}
        notes={notes}
        image={backgroundImage}
        icon={icon}
        buttonLink={bottomButton}
      />
      <Footer />
    </Layout>
  )
}

PublicPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
export default PublicPage
